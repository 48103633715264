<template>
  <div id="app" class="container-fluid px-0">
    <div class="d-xl-flex">
      <sidebar v-if="login_data.success === true" :login_data="login_data" />
      <div class="mh-100vh app-container container-fluid bg-gray px-0">
        <div v-if="login_data.success === true" class="row py-3">
          <div
            class="col-12 col-sm-12 d-flex align-items-center justify-content-end holderTopBar"
          >
            <notifications />

            <div
              class="holderMenuDropDown"
              @mouseover="onOver"
              @mouseleave="onLeave"
            >
              <strong>{{ login_data.user.name }} </strong>
              <i v-if="!menuIsOpen" class="fas fa-chevron-down"></i>
              <i v-else class="fas fa-chevron-up"></i>
              <div id="holderDisplay" v-show="!resetMenu">
                <ul>
                  <li @click="resetDropDown" @ontouchstart="resetDropDown" id="item_my_account">
                    <router-link to="/my-account">My Account</router-link>
                  </li>
                  <li>
                    <a href="#" @click.prevent="logOut">Logout</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="container-1600px">
          <router-view
            
            @loginSuccess="loginData"
            @changeIDReport="changeIDReport"
            @changeIDUser="changeIDUser"
            @setCookie="setCookie"
            @getCookie="getCookie"
            @changeLoginData="changeLoginData"
            :login_data="login_data"
            :id_report="id_report"
            :id_user="id_user"

            @setLogout="logOut"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "./components/Sidebar.vue";
import Notifications from "./components/Notifications.vue";

export default {
  components: {
    Sidebar,
    Notifications
  },
  data() {
    return {
      login_data: [],
      id_report: "",
      id_user: "",
      menuIsOpen: false,
      resetMenu: false
    };
  },
  mounted() {
    let themejs = document.createElement("script");
    themejs.setAttribute(
      "src",
      "//unpkg.com/imagesloaded@4/imagesloaded.pkgd.min.js"
    );
    document.head.appendChild(themejs);
  },
  created() {
    //LOGIN DATA
    if (this.getCookie("login_data") !== "") {
      this.login_data = JSON.parse(this.getCookie("login_data"));
      this.id_report = this.getCookie("id_report");
      this.id_user = this.getCookie("id_user");
    }
  },
  methods: {
    async loginData(data) {
      const self = this;
      if (data !== undefined || data.success === false) {
        if (!data.success) {
          this.makeToast("ERROR", data.error_message, "danger");
        } else {
          this.login_data = data;
          this.setCookie("login_data", JSON.stringify(data));

          //If the user is new cliente then is redirected to carrier connection page
          if(data.is_new_client)
          {
            self.$router.push("/connect-carrier");
            return false;
          }
        
          await this.axios({
            method: "get",
            url: `https://api.expertel.ca/api/v1/client/me/reports`,
            responseType: "json",
            headers: {
              Authorization: "Bearer " + self.login_data.token
            }
          })
            .then(async function(response) {

              if(response.data.length > 0){  
                self.id_report = response.data.sort(
                  (a, b) => new Date(b.report_date) - new Date(a.report_date)
                )[0].id;
                
              }
              self.id_user = self.login_data.user.id;
              self.setCookie("id_report", self.id_report);
              self.setCookie("id_user", self.id_user);

              if (self.login_data.user.type == "admin") {
                location.href = `https://iq.expertel.ca/admin/login?token=${self.login_data.token}`;
              } else {
                self.$router.push("/dashboard");
              }
            })
            .catch(error => this.makeToast("ERROR", error, "danger"));
        }
      } else {
        this.makeToast("ERROR", "Error to login", "danger");
      }
    },
    logOut() {
      this.login_data = [];
      document.cookie.split(";").forEach(function(c) {
        document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });
      this.$router.push("/");
    },
    changeIDReport(id) {
      this.id_report = id;
      this.setCookie("id_report", id);
      this.$router.push("/dashboard");
    },
    changeIDUser(id) {
      this.id_user = id;
      this.$router.push("/settings/password");
    },
    changeLoginData(data) {
      this.login_data = data;
      this.$router.push("/dashboard");
    },
    //makeToast(message, variant = null) {
    makeToast(title, message, variant = null) {  
      this.$bvToast.toast(`${message}`, {
        title: title,
        autoHideDelay: 5000,
        appendToast: true,
        variant: variant
      });
    },
    setCookie(cname, cvalue) {
      document.cookie = cname + "=" + cvalue + ";expires=0;path=/";
    },
    getCookie(cname) {
      var name = cname + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },

    onOver() {
      this.menuIsOpen = true;
      this.resetMenu = false;
    },
    onLeave() {
      this.menuIsOpen = false;
    },
    resetDropDown() {
      this.resetMenu = true;
    }
  }
};
</script>
