import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";

Vue.config.productionTip = false;

//AXIOS
Vue.use(VueAxios, axios);

//BOOTSTRAP AND ICONS COMPONENTS
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "@popperjs/core";

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
//FONT AWESOME
import "@/assets/css/all.min.css";
//BOOTSTRAP STYLES
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@/assets/scss/styles.scss";

//HIGHCHART
import HighchartsVue from "highcharts-vue";
import Highcharts from "highcharts";
import stockInit from "highcharts/modules/stock";
import mapInit from "highcharts/modules/map";
// import exportingInit from "highcharts/modules/exporting";
import more from "highcharts/highcharts-more";
import solidGaugeInit from "highcharts/modules/solid-gauge";
import accecibilityInit from "highcharts/modules/accessibility";
//LOADERS
import { VueSpinners } from "@saeris/vue-spinners";

//SWEET ALERT 2
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);

// exportingInit(Highcharts);
stockInit(Highcharts);
mapInit(Highcharts);
more(Highcharts);
solidGaugeInit(Highcharts);
accecibilityInit(Highcharts);

Vue.use(HighchartsVue);
Vue.use(VueSpinners);

Vue.filter("currencyDolar", function(value) {
  if (!value) return "";
  if (value == 0) {
    return "$0.00";
  }
  let val = (value / 1).toFixed(2);
  return `$${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
});

Vue.filter("percentage", function(value) {
  if (!value) return "";
  let val = 0;
  if (val % 1 == 0) {
    val = value;
  } else {
    val = (value / 1).toFixed(2);
  }

  return `${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%`;
});

Vue.filter("dataGB", function(value) {
  if (!value) return "";
  let val = 0;
  if (val % 1 == 0) {
    val = value;
  } else {
    val = (value / 1).toFixed(2);
  }

  return `${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} GB`;
});

Vue.filter("numbers", function(value) {
  if (!value) return "";
  let val = 0;
  if (val % 1 == 0) {
    val = value;
  } else {
    val = (value / 1).toFixed(2);
  }

  return `${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
});

Vue.filter("phoneNumber", function(value) {
  if (!value) return "";

  if (value.toString().length == 9) {
    let str1 = value.toString().slice(0, 3);
    let str2 = value.toString().slice(3, 6);
    let str3 = value.toString().slice(6, 9);

    return `${str1}-${str2}-${str3}`;
  } else if (value.toString().length == 10) {
    let str1 = value.toString().slice(0, 3);
    let str2 = value.toString().slice(3, 6);
    let str3 = value.toString().slice(6, 10);
    return `(${str1}) ${str2}-${str3}`;
  } else {
    return value;
  }
});

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
