<template>
  <div :class="{ 'sidebar-opened': collapsedMenu }" class="sidebar-primary">
    <div class="menu-content">
      <button @click="collapseMenu()" class="btn-toggler d-none d-xl-block">
        <i class="fas fa-times" v-if="collapsedMenu === true"></i>
        <i class="fal fa-bars" v-else></i>
      </button>
      <b-navbar toggleable="xl">
        <router-link
          class="navbar-brand"
          :to="login_data.length !== 0 ? '/dashboard' : '/'"
        >
          <img
            v-show="collapsedMenu === true"
            src="@/assets/logo.png"
            alt="Expertel IQ"
            class="logo"
          />
          <img
            v-show="collapsedMenu === false"
            src="@/assets/logo-icon.png"
            alt="Expertel IQ"
            class="img-fluid logo-icon"
          />
        </router-link>

        <b-navbar-toggle target="nav-collapse">
          <template #default="{ expanded }">
            <i class="fas fa-times" v-if="expanded"></i>
            <i class="fas fa-bars" v-else></i>
          </template>
        </b-navbar-toggle>

        <b-collapse
          id="nav-collapse"
          is-nav
          :class="{ 'text-left': collapsedMenu }"
        >
          <b-navbar-nav>
            <li v-for="link in menu" :key="link.id" class="nav-item">
              <router-link :to="link.route" class="nav-link">
                <i :class="[link.icon, { 'mr-2': collapsedMenu }]"></i>
                <span v-show="collapsedMenu === true">{{
                  link.title
                }}</span></router-link
              >
            </li>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
  </div>
</template>

<script>
export default {
  props: ["login_data"],
  data() {
    return {
      collapsedMenu: false,
      menu: [
        {
          id: 1,
          title: "Dashboard",
          route: "/dashboard",
          icon: "fal fa-home-lg-alt"
        },
        {
          id: 2,
          title: "IQ",
          route: "/iq",
          icon: "fal fa-database"
        },
        {
          id: 3,
          title: "Reports",
          route: "/reports",
          icon: "fal fa-file-chart-pie"
        },
        {
          id: 4,
          title: "Cost Centres",
          route: "/cost-centres",
          icon: "fal fa-chart-pie-alt"
        },
        {
          id: 5,
          title: "Managers",
          route: "/managers",
          icon: "fal fa-users"
        },
        /*
        {
          id: 6,
          title: "Buyback",
          route: "/buyback",
          icon: "fal fa-mobile-android-alt"
        },
        */
        {
          id: 7,
          title: "Settings",
          route: "/settings",
          icon: "fal fa-cog"
        },
        
        
        
      ]
    };
  },
  methods: {
    collapseMenu() {
      this.collapsedMenu = !this.collapsedMenu;
    }
  }
};
</script>
