import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: () => import("../views/Login/Login.vue")
  },
  {
    path: "/auth/token/:token",
    name: "LoginToken",
    component: () => import("../views/Login/Login.vue")
  },
  {
    path: "/password/:token",
    name: "ResetPasswordToken",
    component: () => import("../views/PasswordTokenReset/TokenReset.vue")
  },
  {
    path: "/dashboard/",
    name: "Dashboard",
    component: () => import("@/views/Dashboard/Dashboard.vue")
  },
  {
    path: "/iq",
    name: "IQ",
    component: () => import("../views/IQ/IQ.vue")
  },
  {
    path: "/reports/",
    name: "Reports",
    component: () => import("../views/Reports/Reports.vue")
  },
  {
    path: "/cost-centres",
    name: "CostCentres",
    component: () => import("../views/CostCentres/CostCentres.vue")
  },
  {
    path: "/managers/:id?",
    name: "Managers",
    component: () => import("../views/Managers/Managers.vue")
  },
  {
    path: "/connect-carrier",
    name: "connect_carrier",
    component: () => import("../views/ConnectCarrier/ConnectCarrier.vue")
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import("../views/Settings/Settings.vue")
  },
  {
    path: "/settings/general",
    name: "Settings_General",
    component: () => import("../views/Settings/General.vue")
  },
  {
    path: "/settings/carriers",
    name: "Settings_Carriers",
    component: () => import("../views/Settings/Carriers.vue")
  },
  {
    path: "/settings/users",
    name: "Settings_Users",
    component: () => import("../views/Settings/Users.vue")
  },
  {
    path: "/settings/billing",
    name: "Settings_Billing",
    component: () => import("../views/Settings/Billing.vue")
  },
  {
    path: "/settings/password",
    name: "Settings_Password",
    component: () => import("../views/Settings/Password.vue")
  },
  {
    path: "/settings/monthly-tips",
    name: "Settings_Tips",
    component: () => import("../views/Settings/Tips.vue")
  },
  {
    path: "/settings/upload-logo",
    name: "Settings_Logos",
    component: () => import("../views/Settings/Logos.vue")
  },
  {
    path: "/settings/report-distribution",
    name: "Settings_Distribution",
    component: () => import("../views/Settings/Distribution.vue")
  },
  {
    path: "/settings/send-reports",
    name: "Send_Reports",
    component: () => import("../views/Settings/SendReports.vue")
  },
  {
    path: "/settings/single_upload",
    name: "UploadReports",
    component: () => import("../views/Settings/UploadReports.vue")
  },
  {
    path: "/settings/multiple_upload",
    name: "MultipleUploadReports",
    component: () => import("../views/Settings/MultipleUploadReports.vue")
  },
  {
    path: "/settings/upgrades",
    name: "UploadUpgradesInfoFile",
    component: () => import("../views/Settings/UploadUpgradesInfoFile.vue")
  },
  {
    path: "/optimizer",
    name: "Optimizer",
    component: () => import("../views/Optimizer/Optimizer.vue")
  },
  {
    path: "/auditor",
    name: "AuditorPlus",
    component: () => import("../views/AuditorPlus/AuditorPlus.vue")
  },
  {
    path: "/alerts",
    name: "Alerts",
    component: () => import("../views/Alerts/Alerts.vue")
  },
  {
    path: "/my-account",
    name: "MyAccount",
    component: () => import("../views/MyAccount/MyAccount.vue")
  },
  {
    path: "/view_client",
    name: "ViewClient",
    component: () => import("../views/MyAccount/ViewClient.vue")
  },
  {
    path: "/buyback",
    name: "Buyback",
    component: () => import("../views/Buyback/Buyback.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  linkExactActiveClass: "active",
  base: process.env.BASE_URL,
  routes
});

export default router;
